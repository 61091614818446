exports.components = {
  "component---src-pages-buy-trx-index-js": () => import("./../../../src/pages/buy-trx/index.js" /* webpackChunkName: "component---src-pages-buy-trx-index-js" */),
  "component---src-pages-buy-trx-unavailable-js": () => import("./../../../src/pages/buy-trx/unavailable.js" /* webpackChunkName: "component---src-pages-buy-trx-unavailable-js" */),
  "component---src-pages-community-events-js": () => import("./../../../src/pages/community/events.js" /* webpackChunkName: "component---src-pages-community-events-js" */),
  "component---src-pages-community-home-js": () => import("./../../../src/pages/community/home.js" /* webpackChunkName: "component---src-pages-community-home-js" */),
  "component---src-pages-community-policy-report-js": () => import("./../../../src/pages/community/policy-report.js" /* webpackChunkName: "component---src-pages-community-policy-report-js" */),
  "component---src-pages-community-trading-js": () => import("./../../../src/pages/community/trading.js" /* webpackChunkName: "component---src-pages-community-trading-js" */),
  "component---src-pages-ecosystem-climate-js": () => import("./../../../src/pages/ecosystem-climate.js" /* webpackChunkName: "component---src-pages-ecosystem-climate-js" */),
  "component---src-pages-ecosystem-fund-js": () => import("./../../../src/pages/ecosystem-fund.js" /* webpackChunkName: "component---src-pages-ecosystem-fund-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-hackathon-judges-js": () => import("./../../../src/pages/hackathon/judges.js" /* webpackChunkName: "component---src-pages-hackathon-judges-js" */),
  "component---src-pages-hackathon-judging-js": () => import("./../../../src/pages/hackathon/judging.js" /* webpackChunkName: "component---src-pages-hackathon-judging-js" */),
  "component---src-pages-hackathon-partners-js": () => import("./../../../src/pages/hackathon/partners.js" /* webpackChunkName: "component---src-pages-hackathon-partners-js" */),
  "component---src-pages-hackathon-projects-js": () => import("./../../../src/pages/hackathon/projects.js" /* webpackChunkName: "component---src-pages-hackathon-projects-js" */),
  "component---src-pages-hackathon-tracks-academy-js": () => import("./../../../src/pages/hackathon/tracks/academy.js" /* webpackChunkName: "component---src-pages-hackathon-tracks-academy-js" */),
  "component---src-pages-hackathon-tracks-defi-js": () => import("./../../../src/pages/hackathon/tracks/defi.js" /* webpackChunkName: "component---src-pages-hackathon-tracks-defi-js" */),
  "component---src-pages-hackathon-tracks-gamefi-js": () => import("./../../../src/pages/hackathon/tracks/gamefi.js" /* webpackChunkName: "component---src-pages-hackathon-tracks-gamefi-js" */),
  "component---src-pages-hackathon-tracks-js": () => import("./../../../src/pages/hackathon/tracks.js" /* webpackChunkName: "component---src-pages-hackathon-tracks-js" */),
  "component---src-pages-hackathon-tracks-nft-js": () => import("./../../../src/pages/hackathon/tracks/nft.js" /* webpackChunkName: "component---src-pages-hackathon-tracks-nft-js" */),
  "component---src-pages-hackathon-tracks-web-3-js": () => import("./../../../src/pages/hackathon/tracks/web3.js" /* webpackChunkName: "component---src-pages-hackathon-tracks-web-3-js" */),
  "component---src-pages-hackatron-js": () => import("./../../../src/pages/hackatron.js" /* webpackChunkName: "component---src-pages-hackatron-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-faq-js": () => import("./../../../src/pages/learning/faq.js" /* webpackChunkName: "component---src-pages-learning-faq-js" */),
  "component---src-pages-on-off-ramp-js": () => import("./../../../src/pages/on-off-ramp.js" /* webpackChunkName: "component---src-pages-on-off-ramp-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-resources-art-js": () => import("./../../../src/pages/resources/art.js" /* webpackChunkName: "component---src-pages-resources-art-js" */),
  "component---src-pages-resources-finance-js": () => import("./../../../src/pages/resources/finance.js" /* webpackChunkName: "component---src-pages-resources-finance-js" */),
  "component---src-pages-resources-gaming-js": () => import("./../../../src/pages/resources/gaming.js" /* webpackChunkName: "component---src-pages-resources-gaming-js" */),
  "component---src-pages-resources-get-started-js": () => import("./../../../src/pages/resources/get-started.js" /* webpackChunkName: "component---src-pages-resources-get-started-js" */),
  "component---src-pages-resources-governance-js": () => import("./../../../src/pages/resources/governance.js" /* webpackChunkName: "component---src-pages-resources-governance-js" */),
  "component---src-pages-resources-multimedia-js": () => import("./../../../src/pages/resources/multimedia.js" /* webpackChunkName: "component---src-pages-resources-multimedia-js" */),
  "component---src-pages-tronpedia-blockchain-js": () => import("./../../../src/pages/tronpedia/blockchain.js" /* webpackChunkName: "component---src-pages-tronpedia-blockchain-js" */),
  "component---src-pages-tronpedia-crypto-js": () => import("./../../../src/pages/tronpedia/crypto.js" /* webpackChunkName: "component---src-pages-tronpedia-crypto-js" */),
  "component---src-pages-tronpedia-decentralized-finance-defi-js": () => import("./../../../src/pages/tronpedia/decentralized-finance-defi.js" /* webpackChunkName: "component---src-pages-tronpedia-decentralized-finance-defi-js" */),
  "component---src-pages-tronpedia-gamefi-js": () => import("./../../../src/pages/tronpedia/gamefi.js" /* webpackChunkName: "component---src-pages-tronpedia-gamefi-js" */),
  "component---src-pages-tronpedia-js": () => import("./../../../src/pages/tronpedia.js" /* webpackChunkName: "component---src-pages-tronpedia-js" */),
  "component---src-pages-tronpedia-nft-js": () => import("./../../../src/pages/tronpedia/nft.js" /* webpackChunkName: "component---src-pages-tronpedia-nft-js" */),
  "component---src-pages-tronpedia-search-js": () => import("./../../../src/pages/tronpedia/search.js" /* webpackChunkName: "component---src-pages-tronpedia-search-js" */),
  "component---src-pages-tronpedia-stablecoin-js": () => import("./../../../src/pages/tronpedia/stablecoin.js" /* webpackChunkName: "component---src-pages-tronpedia-stablecoin-js" */),
  "component---src-pages-tronpedia-web-3-js": () => import("./../../../src/pages/tronpedia/web3.js" /* webpackChunkName: "component---src-pages-tronpedia-web-3-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-directory-js": () => import("./../../../src/templates/blogDirectory.js" /* webpackChunkName: "component---src-templates-blog-directory-js" */),
  "component---src-templates-conferences-js": () => import("./../../../src/templates/conferences.js" /* webpackChunkName: "component---src-templates-conferences-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-judge-js": () => import("./../../../src/templates/judge.js" /* webpackChunkName: "component---src-templates-judge-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

